import templateStr from 'text!./pm-payment-contact-details.html';
import moment from 'moment';

export default ['StateUtils', (StateUtils) => {
    return {
        restrict: 'E',
        scope: {
            bindData: '=',
            baseData: '=',
            submission: '='
        },
        template: templateStr,
        controller: ['$scope', '$filter', '$q', 'StaticDocumentsService_AND', '$rootScope', 'qnb.PolicyService', ($scope, $filter, $q, StaticDocumentsService_AND, $rootScope, PolicyService) => {
            
            $scope.contact = $scope.baseData.accountHolder;

            $q.all([
                StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.PRIVACY_POLICY),
                StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.PRIVACY_NOTICE),
            ]).then((result) => {
                $scope.privacyPolicyDoc = result[0];
                $scope.privacyNoticeDoc = result[1];
            });

            $scope.contactPhone = $scope.bindData.contactPhone;
            if ($scope.contactPhone !== undefined) {
                if ($scope.contactPhone.value !== null && !$scope.contactPhone.aspects.valid) {
                    $scope.contactPhone.value = '07000000000';
                }
            }
            $scope.policyEffDateBeforeAndUk = $scope.submission.value.policyEffDateBeforeAndUk();
            $scope.DefactoDateEvaluation = PolicyService.getDefactoDate_AND()
                .then((scriptDate) => {
                     $scope.isPolicyEffDateBeforeItbDefactoDate = $scope.submission.value.isPolEffDateBeforeDefactoDate(scriptDate);
                });
            $q.all([
                StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.PRIVACY_POLICY, $scope.baseData.periodStartDate.value),
                StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.PRIVACY_NOTICE, $scope.baseData.periodStartDate.value),
                StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.TERMS_AND_CONDITIONS, $scope.baseData.periodStartDate.value),
                StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.CORE_IPID, $scope.baseData.periodStartDate.value),
                StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.PRIVATE_CAR_POLICY_DOCUMENT, $scope.baseData.periodStartDate.value),
                StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.COOKIE_POLICY, $scope.baseData.periodStartDate.value),
                StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.IMPORTANT_INFORMATION, $scope.baseData.periodStartDate.value),
                StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.KEYS_IPID, $scope.baseData.periodStartDate.value),
                StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.LEXUS_TERMS, $scope.baseData.periodStartDate.value),
                StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.HYBRID_TERMS, $scope.baseData.periodStartDate.value)
            ])
                .then((result) => {
                    $scope.privacyPolicyDocUrl = result[0];
                    $scope.privcyNoticeDocUrl = result[1];
                    $scope.termsDocUrl = result[2];
                    $scope.coreIpidDocUrl = result[3];
                    $scope.policyDocUrl = result[4];
                    $scope.cookiePolicyDocUrl = result[5];
                    $scope.importantInformationDocUrl = result[6];
                    $scope.keysIPID_URL = result[7];
                    $scope.lexusTsandCsDoc = result[8];
                    $scope.hybridTsandCsDoc = result[9];
                });

            $scope.isTodayAfterANDUK = () =>{
                return moment().isAfter(moment('20201201').endOf('day'));
            };

            $scope.coverageSelection = StateUtils.activeDataForState(true).coverageSelection;

            const vehicle = $scope.submission.lobData.personalMotor.coverables.pmVehicles[0] ? $scope.submission.lobData.personalMotor.coverables.pmVehicles[0] : $scope.submission.lobData.personalMotor.coverables.pmVehicles.value[0];
            $scope.usageBasedInsurance = vehicle.usageBasedInsurance;
            $scope.fullHybridInsurance = vehicle.fullHybridInsurance;

            $scope.declarationOptions = [
                {
                    id: 'DeclarationAccept',
                    value: true,
                    label: 'and.quoteandbind.pm.views.paymentstep.1.Accept'
                },
                {
                    id: 'DeclarationDecline',
                    value: false,
                    label: 'and.quoteandbind.pm.views.paymentstep.1.Decline'
                }
            ];
            
            $scope.$watch('contact.marketingPrefOptOut_itb.value', (newValue) => { 
                if (newValue === undefined || newValue === false) {
                    $scope.contact.marketingPrefOptOut_itb.value = false;
                    $scope.contact.marketingPrefEmail_itb.value = true;
                    $scope.contact.marketingPrefSMS_itb.value = true;
                    $scope.contact.marketingPrefVoiceMob_itb.value = true;
                    $scope.contact.marketingPrefLandline_itb.value = true;
                    $scope.contact.marketingPrefPost_itb.value = true;
                    $scope.contact.marketingPrefMobileApp_itb.value = true;
                } else if (newValue === true) {
                    $scope.contact.marketingPrefOptOut_itb.value = true;
                    $scope.contact.marketingPrefEmail_itb.value = false; 
                    $scope.contact.marketingPrefSMS_itb.value = false;
                    $scope.contact.marketingPrefVoiceMob_itb.value = false;
                    $scope.contact.marketingPrefLandline_itb.value = false;
                    $scope.contact.marketingPrefPost_itb.value = false;
                    $scope.contact.marketingPrefMobileApp_itb.value = false;
                }
            });
        }]
    };
}];