import templateStr from 'text!./pm-hybrid-insurance.html';

export default ['$filter', ($filter) => {
    return {
        restrict: 'E',
        scope: {
            vehicle: '='
        },
        template: templateStr,
        controller: ['$scope', '$q', 'ModalService', 'brandingData', 'qnb.PolicyService', 'StaticDocumentsService_AND', ($scope, $q, ModalService, brandingData, PolicyService, StaticDocumentsService_AND) => {
            $scope.hybridInsuranceVM = {
                value: null,
                aspects: {
                    required: true,
                    availableValues: [true, false],
                    get valid() {
                        return angular.isDefined($scope.hybridInsuranceVM.value) && $scope.hybridInsuranceVM.value !== null;
                    },
                    get validationMessages() {
                        if (this.valid === false) {
                            return [$filter('translate')('displaykey.Edge.Web.Api.Model.NotNull')];
                        }
                        return [];
                    }
                }
            };

            $q.all([
                StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.HYBRID_TERMS),
            ]).then((result) => {
                $scope.hybridTsandCsDoc = result[0];
            });


            if($scope.vehicle.fullHybridInsurance.value === true) {
                $scope.hybridInsuranceVM.value = true;
            } else {
                $scope.hybridInsuranceVM.value = null;
            }

            $scope.$watch('hybridInsuranceVM.value', (newValue) => {
                if (newValue !== null && $scope.vehicle.fullHybridInsurance.value !== newValue) {
                    $scope.vehicle.fullHybridInsurance = newValue;
                }
            });
            $scope.selectOption = (option, $event) => {
                if ($event.target.tagName === 'A') { // Ignore clicks to the document anchor links
                    return;
                }
                if ($scope.vehicle.updateInFlight !== true) {
                    $scope.hybridInsuranceVM.value = option;
                }
            };
        }],

    };
}];