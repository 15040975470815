import templateStr from 'text!./pm-lexus-mileage-insurance.html';

export default ['$filter', ($filter) => {
    return {
        restrict: 'E',
        scope: {
            vehicle: '='
        },
        template: templateStr,
        controller: ['$scope', '$q', 'ModalService', 'brandingData', 'qnb.PolicyService', 'StaticDocumentsService_AND', ($scope, $q, ModalService, brandingData, PolicyService, StaticDocumentsService_AND) => {
            $scope.lexusMileageInsuranceVM = {
                value: null,
                aspects: {
                    required: true,
                    availableValues: [true, false],
                    get valid() {
                        return angular.isDefined($scope.lexusMileageInsuranceVM.value) && $scope.lexusMileageInsuranceVM.value !== null;
                    },
                    get validationMessages() {
                        if (this.valid === false) {
                            return [$filter('translate')('displaykey.Edge.Web.Api.Model.NotNull')];
                        }
                        return [];
                    }
                }
            };

            $q.all([
                StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.LEXUS_TERMS),
            ]).then((result) => {
                $scope.lexusTsandCsDoc = result[0];
            });

            if ($scope.vehicle.usageBasedInsurance.value === true) {
                $scope.lexusMileageInsuranceVM.value = true; 
            } else {
                $scope.lexusMileageInsuranceVM.value = null; 
            }
            $scope.$watch('lexusMileageInsuranceVM.value', (newValue) => {
                if (newValue !== null && $scope.vehicle.usageBasedInsurance.value !== newValue) {
                    $scope.vehicle.usageBasedInsurance = newValue;
                }
            });
            $scope.selectOption = (option, $event) => {
                if ($event.target.tagName === 'A') { // Ignore clicks to the document anchor links
                    return;
                }
                if ($scope.vehicle.updateInFlight !== true) {
                    $scope.lexusMileageInsuranceVM.value = option;
                }
            };
        }],

    };
}];