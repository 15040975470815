import LocalDateUtil from 'gw-portals-util-js/LocalDateUtil';
import moment from 'moment';

export default ['$q', 'brandingData', ($q, brandingData) => {

    const pathname = window.location.pathname;
    const endpoint = `${pathname.substring(0, pathname.indexOf('/', 1))}/docs/${brandingData}/`;
    const DOCS = {
        CORE_IPID: 'insurance_product_information_document',
        CCCP_IPID: 'cccplus_insurance_product_information_document',
        CCCP_POLICY: 'cccplus_policy_document',
        KEYS_IPID: 'keys_insurance_product_information_document',
        KEYS_POLICY: 'keys_policy_document',
        BREAKDOWN_IPID_BASIC: 'breakdown_basic_insurance_product_information_document',
        BREAKDOWN_IPID_EURO: 'breakdown_euro_insurance_product_information_document',
        BREAKDOWN_IPID_LVL2: 'breakdown_finest_insurance_product_information_document',
        BREAKDOWN_POLICY: 'breakdown_policy_document',
        PRIVACY_POLICY: 'privacy_policy',
        TERMS_AND_CONDITIONS: 'terms_and_conditions',
        IMPORTANT_INFORMATION: 'important_information',
        PRIVATE_CAR_POLICY_DOCUMENT: 'private_car_policy_document',
        PRIVACY_NOTICE: 'privacy_notice',
        COOKIE_POLICY: 'cookie_policy',
        LEGAL_EXP_IPID: 'legal_expenses_insurance_product_information_document',
        LEGAL_EXP_POLICY: 'legal_expenses_policy_document',
        ECCC_IPID: 'eccc_insurance_product_information_document',
        ECCC_POLICY: 'eccc_policy_document',
        MOTOR_EXCESS_IPID: 'motor_excess_protect_insurance_product_information_document',
        MOTOR_EXCESS_POLICY: 'motor_excess_protect_policy_document',
        PERSONAL_ACCIDENT_IPID: 'personal_accident_insurance_product_information_document',
        PERSONAL_ACCIDENT_POLICY: 'personal_accident_policy_document',
        TOOLS_IN_TRANSIT_IPID: 'tools_in_transit_insurance_product_information_document',
        TOOLS_IN_TRANSIT_POLICY: 'tools_in_transit_policy_document',
        LEXUS_TERMS: 'lexusrz_terms_and_conditions',
        HYBRID_TERMS: 'hybrid_insurance_terms_and_conditions'
    };
    const docsPromise = fetch(endpoint)
        .then((response) => {
            if (response.ok !== true) {
                return;
            }
            return response.text().then((text) => 
                text.match(/href="([\w-]+)/g)
                    .map((x) => x.replace('href="', '')));
        })
		.catch(err => {
			console.log("Docs Service call error: ", err);
		});
    
    function getDocsByName(docsName) {
        return docsPromise.then((docs) => {
            return docs.filter(el => el.toLowerCase().startsWith(docsName.toLowerCase()));
        });
    }


    function getEffDatedDoc(docName, effDate) {
        return getDocsByName(docName)
            .then((docs) => {
                const docsMilli = docs.map((x) => parseDate(x))
                    .sort((a, b) => { return b - a; })
                    .filter((element) => effDate >= element);
                const docToShow = `${window.location.origin}${endpoint}/${docName}-${moment(docsMilli[0]).format('DD-MM-YYYY')}`;
                return docToShow;

            });
    }

    /*
     * Gets the date from the document name and returns the number of milliseconds between that date and midnight
     * eg. doc_name-01-01-2019 is formatted as 01/01/2019 before parsing
     */
    function parseDate(doc) {
        const docParts = doc.split('-');
        return moment(`${docParts[3]}-${docParts[2]}-${docParts[1]}`, moment.ISO_8601).valueOf();
    }
    
    return {
        DOCS,
        getDocURL: (docKey, effDateDTO = LocalDateUtil.today()) => {
            return getEffDatedDoc(docKey, LocalDateUtil.toMidnightDate(effDateDTO)).then((docName) => `${docName}.pdf`);
        }
    };
}];